body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
}

/*all text */
* label, p, h3, h4, h5, h6, a, span, button {
  color: #818181;
}

* h1, h2 {
  color: #6b6b6b;
}

.device-definition-sgr img {
  width: 20%;
  height: 20%;
  margin-top: -1%;
  margin-left: 8%;
  position: absolute;
  /*margin-bottom: 15p  x;*/
}

.device-definition Button {
  margin-top: -3%;
  /*margin-left: -5%;*/
  position: absolute;
}

.device-definition-sgr {
  color: #3da278;
}

.configurator {
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 20px;
  /*padding-top: 10px;*/
  padding-bottom: 20px;
  border-radius: 10px;
  background-color: white;
  transition: height 0.5s ease-out;
  max-height: 90vh;
  min-height: 90vh;
  overflow: auto;
}


.sidebar {
}

.sidebar > .sidebar-content {
  flex-grow: 1; /* Allow the sidebar content to take up the remaining space */
  width: 100%;;
}

.edge-card {
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 20%) 5px 5px 15px;
  margin: 5px;
  transition: transform 0.05s ease;
}

.edge-card:hover {
  transform: scale(1.01);
  transition: transform 0.1s ease;
}


.forgot-password {
  color: #818181;
  text-decoration: none;
  font-size: 12px;
  text-align: end;
}

.forgot-password * button {
  color: #818181;
  text-decoration: none;
  margin: 10px;
}

.kein-konto {
  color: #818181;
  text-decoration: none;
  font-size: 12px;
  margin-bottom: 30px;
}

.kein-konto > a {
  color: #818181;
  text-decoration: none;
  font-size: 12px;

  font-weight: bold
}

.Auth-form {
  margin-top: 5vh;
  width: 520px;
  box-shadow: rgb(0 0 0 / 20%) 0px 10px 30px;
  padding-top: 60px;
  border-radius: 50px;
  background-color: white;
}

* .Auth-form-content > div > p {
  color: #5e5e5e;
  font-size: 15px;
  text-align: start;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.recharts-cartesian-axis-tick {
  font-size: 0.8rem;
}


/* SGrDeviceForm */

.placeholder-container {
  margin-top: 20px;
  padding: 15px;
  box-shadow: inset 0 0 10px rgb(225, 225, 225); /* Inner shadow */
  border-radius: 5px;
  background-color: #ffffff;
}


.placeholder-container p {
  color: #4dc290;
  font-size: 19px;
  font-weight: bolder;
  margin-bottom: 20px;
}

.placeholder-container img {
  width: 25%;
  height: auto;
  border-radius: 5px;
  margin-top: -20px;
  margin-bottom: 15px;
}

.xml-file {
  display: none;
}

.custom-file-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4dc290;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.3s ease-in-out; /* Add this line */

}

.custom-file-button:hover {
  background-color: #3da278;
  transition: background-color 0.2s ease-in-out;
}


.sgr-save {
  display: inline-block;
  padding: 7px 10px;
  background-color: #4dc290;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: background-color 0.2s ease-in-out;
}

.sgr-save:hover {
  background-color: #3da278;
  transition: background-color 0.2s ease-in-out;
}

.xml-placeholder-field {
  margin-bottom: 15px;
  transition: background-color 0.2s ease-in-out;
}

.xml-placeholder-field .form-control {
  border-color: #4dc290; /* Custom border for inputs */
  background-color: #e7f3ff; /* Light blue background for placeholders */
}
