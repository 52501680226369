.pac-container {
  background-color: #FFF;
  z-index: 2001;
  position: fixed;
  display: inline-block;
  float: left;
}

.modal {
  z-index: 2000;
}

.modal-backdrop {
  z-index: 1000;
}

.autocomplete {
  position: relative;
  width: 100%;
  z-index: 4001;
}